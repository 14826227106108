<template>
    <div class="cartNotice">
		<center>
			<p v-if="!sent">Your account has not been verified yet</p>
			<p v-else><i class='uil uil-check-circle'></i>Check your email and click the verify link we sent</p>
			<div class="action" v-if="!sent">
				<p class="b" style="background-color: green;" @click.prevent="submitForVerification" v-if="!Loading">Verify Now</p>
				<p class="b" style="background-color: brown;" v-else>Sending mail ...</p>
				<!-- <p class="b" style="background-color: red;" >Clear Cart</p> -->
			</div>
		</center>
	</div>
    
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'VerifyPop',
	data(){
		return{
			userId : localStorage.getItem('fmruser'),
			sent: false,
			Loading: false
		}
	},
    props: {
        msg: Number
	},
	computed: {
		...mapGetters(['publicToken'])
	},
	methods: {
		...mapActions(['requestVerifyUser']),
		submitForVerification(){
			this.Loading = true
			let object = {
				userId: this.userId, 
				publicToken: this.publicToken
			}
			this.requestVerifyUser(object)
			.then(res => {
				this.Loading = false
				if (res.data.success) {
					this.sent = true
				}
			})
			.catch(err => {
				console.log(err)
				alert('Opps, something went wrong')
				this.Loading = false
			})
		}
	},
	mounted(){
		this.domain = window.location.hostname
	}
}
</script>
<style scoped>

	.cartNotice{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		background-color: black;
		opacity: 0.7;
		z-index: 10000000000000000000000000000000000000000000000000000;
	}

	.cartNotice p{
		opacity: 1;
		color: white;
		font-size: 14px;
        margin: 0px
	}

	.cartNotice .action{
		opacity: 1;
		height: 40px;
	}

	.cartNotice .action p{
		padding: 6px;
		border-radius: 5px;
		display: inline-block;
		color: white;
		font-size: 13px;
		margin: auto;
		font-weight: bolder;
		margin-left: 10px;
		margin-right: 10px;
		cursor: pointer;
	}

</style>