<template>
    <div class="alert alert-danger">
        <center>
            <img src="./assets/icon.png" width="100" alt="">
        <slot />
        </center>
    </div>
</template>

<script>
export default {
    name: 'Success'
}
</script>

<style scoped>
.alert{
    border-radius: 3px;
}
.alert-danger{
    background: transparent;
    color: grey
}
p{
    color: grey;
}
</style>